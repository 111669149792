<template>
	<CookieTemplateLayout
		:header-title="'Cookie Banners' | useLabels('Cookie Banners')"
		:header-caption="'Add, edit and manage cookie banners' | useLabels('Add, edit and manage cookie banners')"
	>
		<template #content>
			<FilterBar
				:search-query.sync="cookieBannerSearchQuery"
				search-query-label="Cookie Banner Name"
				:selected-brand-id.sync="selectedAdminPortalBrandId"
				@persistSearchQuery="changeCookieBannerSearchQuery"
				@persistSelectedBrandId="changeAdminPortalBrandFilter"
			>
				<template #action>
					<!-- add permission -->
					<PrimaryActionButton
						v-if="userCanCreateUpdate"
						@click="onCreateClick()"
					>
						<v-icon left>
							mdi-plus
						</v-icon>
						Create
					</PrimaryActionButton>
				</template>
			</FilterBar>
			<SectionCard>
				<template #title>
					Cookie Banners
				</template>
				<template #body>
					<DataTable
						:items="filteredBanners"
						:headers="tableHeaders"
						sort-by="cookieBannerId"
						sort-desc
						@click:row="onEditClick"
					>
						<template #item.action="{ item }">
							<IconButton
								v-if="userCanCreateUpdate"
								tooltip-text="Edit Cookie Banner"
								@click="onEditClick(item)"
							>
								mdi-pencil
							</IconButton>
							<IconButton
								v-else
								tooltip-text="View Cookie Banner"
								@click="onEditClick(item)"
							>
								mdi-eye
							</IconButton>
							<IconButton
								v-if="userCanCreateUpdate"
								tooltip-text="Clone Cookie Banner"
								@click.stop.prevent="onCloneClick(item)"
							>
								mdi-content-duplicate
							</IconButton>

							<IconButton
								v-if="userCanDelete"
								tooltip-text="Force Cookie Banner Reconsent"
								@click.stop.prevent="cookieBannerToForceReconsent = item"
							>
								mdi-cookie-refresh-outline
							</IconButton>

							<IconButton
								v-if="userCanDelete"
								tooltip-text="Delete Cookie Banner"
								@click.stop.prevent="cookieBannerToDelete = item"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
				</template>
			</SectionCard>
			<ConfirmDeleteModal
				v-if="cookieBannerToDelete && userCanDelete"
				:entity-name="cookieBannerToDelete.name"
				entity-type="Cookie Bannner"
				@close="cookieBannerToDelete = null"
				@delete="deleteCookieBanner"
			/>
			<ConfirmationModal
				v-if="cookieBannerToForceReconsent && userCanCreateUpdate"
				title-text="Consent Information"
				@cancel="cookieBannerToForceReconsent = null"
				@confirm="forceCookieBannerReconsent"
			>
				<template #modal-content>
					Please confirm if you are happy to request new consents for the selected cookie banner.
				</template>
			</ConfirmationModal>
		</template>
	</CookieTemplateLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import CookieTemplateLayout from '../cookie-template-layout.vue'
import FilterBar from '../../../../../../../shared/components/filter-bar.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import ConfirmDeleteModal from '../../../../../../../shared/components/confirm-delete-modal.vue'
import ConfirmationModal from '../../../../../../../shared/components/confirmation-modal.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import { useLabels } from '../../../../../../../shared/state/admin-portal-navigation.js'
import { MANAGE_COOKIE_BANNER } from '../../../../../router/route-names.js'
import { getCookieBanners, deleteCookieBanner, cookieBannerForceReconsent } from '../../../../../../../shared/utils/api/cookies/cookie-banners.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import { CAN_CREATE_UPDATE_COOKIE_BANNERS, CAN_DELETE_COOKIE_BANNERS } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import { changeCookieBannerSearchQuery, cookieBannerSearchQuery } from '../../../../../../../shared/state/cookie-banners.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../../../shared/state/brands.js'
export default {
	components: {
		CookieTemplateLayout,
		FilterBar,
		PrimaryActionButton,
		SectionCard,
		DataTable,
		IconButton,
		ConfirmDeleteModal,
		ConfirmationModal
	},
	setup () {
		return {
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			changeCookieBannerSearchQuery,
			cookieBannerSearchQuery
		}
	},
	data () {
		return {
			cookieBanners: [],
			cookieBannerToDelete: null,
			cookieBannerToForceReconsent: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_COOKIE_BANNERS)
		},
		userCanDelete () {
			return this.productAreaPermission(CAN_DELETE_COOKIE_BANNERS)
		},
		tableHeaders () {
			return [
				{ value: 'cookieBannerId', text: 'ID', width: '5%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '10%' },
				{ value: 'name', text: 'Cookie Banner Name', width: '15%' },
				{ value: 'cookieCollectionTemplateName', text: 'Cookie Collection Template', width: '15%' },
				{ value: 'cookieTextTemplateName', text: 'Text Template', width: '15%' },
				{ value: 'cookieAppearanceTemplateName', text: 'Appearance Template', width: '15%' },
				{ value: 'createdDate', text: 'Created Date', width: '10%' },
				{ value: 'action', text: 'Action', width: '10%', sortable: false }
			]
		},
		filteredBanners () {
			return this.cookieBanners.filter(({ name, brandId }) => {
				let check = true
				if (cookieBannerSearchQuery.value) check = name.toLowerCase().includes(cookieBannerSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				return check
			}).map(banner => ({
				...banner
			}))
		}
	},
	created () {
		this.getCookieBanners()
	},
	methods: {
		async getCookieBanners () {
			const { cookieBanners } = await getCookieBanners()
			this.cookieBanners = cookieBanners
		},
		onCreateClick () {
			this.$router.push({
				name: MANAGE_COOKIE_BANNER
			})
		},
		onEditClick (item) {
			this.$router.push({
				name: MANAGE_COOKIE_BANNER,
				params: {
					cookieBannerToEdit: item
				}
			})
		},
		onCloneClick (item) {
			const cookieBannerToEdit = {
				...item,
				name: `${item.name} (Clone)`
			}
			this.$router.push({
				name: MANAGE_COOKIE_BANNER,
				params: {
					cookieBannerToEdit: cookieBannerToEdit,
					cloneMode: true
				}
			})
		},
		async forceCookieBannerReconsent () {
			await cookieBannerForceReconsent(this.cookieBannerToForceReconsent.cookieBannerId)
			showSnackbar(`Cookie Banner ${this.cookieBannerToForceReconsent.name} has been forced to reconsent`)
			this.cookieBannerToForceReconsent = null
		},
		async deleteCookieBanner () {
			await deleteCookieBanner(this.cookieBannerToDelete.cookieBannerId)
			showSnackbar(`You have removed this cookie banner`)
			this.cookieBannerToDelete = null
			this.getCookieBanners()
		}
	}
}
</script>
