import { format } from 'date-fns'
import { DATE_FORMAT } from '../../date-formatting.js'
import { configApi } from '../../http-client.js'

export const getCookieBanners = () => configApi.get('/api/CookieBanner').then(({ data: { cookieBanners } }) => ({
	cookieBanners: cookieBanners.map(banner => ({
		...banner,
		enableCis: banner.identityServiceEnabled,
		allowedDomainsValue: banner.allowedDomains === null || banner.allowedDomains?.length === 0 || banner.allowedDomains?.toLowerCase() === 'all',
		allowedDomains: banner.allowedDomains?.length > 0 ? banner.allowedDomains?.split(',') : [],
		createdDate: format(new Date(banner.createdDate), DATE_FORMAT)
	}))
}))

export const postCookieBanner = cookieBanner => configApi.post('/api/CookieBanner', cookieBanner)

export const putCookieBanner = cookieBanner => configApi.put('/api/CookieBanner', cookieBanner)

export const deleteCookieBanner = cookieBannerId => configApi.delete(`/api/CookieBanner/${cookieBannerId}`)

export const cookieBannerForceReconsent = cookieBannerId => configApi.get(`/api/CookieBanner/RequestReconsent/${cookieBannerId}`)

export const getCookieBannerEmbedCode = (cookieBannerId, languageCode, domain, dynamic) => {
	let embedCodeEndpoint = `/api/CookieBanner/EmbedCode?CookieBannerId=${cookieBannerId}`
	if (languageCode) embedCodeEndpoint += `&LanguageCode=${languageCode}`
	if (domain) embedCodeEndpoint += `&Domain=${domain}`
	if (dynamic) embedCodeEndpoint += `&Dynamic=${dynamic}`
	return configApi.get(embedCodeEndpoint)
}
